import axios from 'axios'

const apiClient = axios.create({
  baseURL: 'https://web-api-sgp.piebarometre.digital/',
  withCredentials: false,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: 'Bearer ' + localStorage.getItem('token'),
  },
})

export default {
  getLinesDI (id) {
    return apiClient.get(`api/request-parametre-intervention/get-ligne-parametrage/${id}`)
  },
  createLinesDI (LinesDI) {
    const formData = new FormData()
    formData.append('libelle_ligne_parametre_domaine_interventions', LinesDI.libelle_ligne_parametre_domaine_interventions)
    formData.append('id_parametrage_domaine_interventions', LinesDI.id_parametrage_domaine_interventions)
    formData.append('choise_indicateur', LinesDI.choise_indicateur)
    formData.append('id_composants', LinesDI.id_composants)
    formData.append('type_valeur', LinesDI.type_valeur)
    formData.append('mesures', LinesDI.mesures)
    return apiClient.post('api/request-parametre-intervention/add-ligne-parametrage', formData)
  },

  updateLinesDI (LinesDI) {
    const formData = new FormData()
    formData.append('libelle_ligne_parametre_domaine_interventions', LinesDI.libelle_ligne_parametre_domaine_interventions)
    formData.append('id_parametrage_domaine_interventions', LinesDI.id_parametrage_domaine_interventions)
    formData.append('choise_indicateur', LinesDI.choise_indicateur)
    formData.append('id_composants', LinesDI.id_composants)
    formData.append('type_valeur', LinesDI.type_valeur)
    formData.append('mesures', LinesDI.mesures)
     return apiClient.post(`api/request-parametre-intervention/update-ligne-parametrage/${LinesDI.id}`, formData)
  },

  deleteLinesDI (LinesDI) {
    return apiClient.post(`api/request-parametre-intervention/delete-ligne-parametrage/${LinesDI.id}`)
 },
}
