<template>
  <v-row>
    <v-col
      cols="12"
      sm="6"
      md="6"
    >
      <v-data-table
        :headers="headers"
        :items="LinesDI"
        :items-per-page="5"
        class="elevation-1"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>
              Ligne domaine d'intervention
            </v-toolbar-title>
            <v-spacer />
            <v-dialog
              v-model="dialog"
              max-width="500px"
            >
              <template v-slot:activator="{ on, attrs}">
                <v-btn
                  color="primary"
                  dark
                  class="mb-2"
                  v-bind="attrs"
                  v-on="on"
                >
                  Nouveau
                </v-btn>
              </template>
              <v-card>
                <v-card-title>
                  <span class="text-h5">{{ formTitle }}</span>
                </v-card-title>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col
                        cols="12"
                        sm="6"
                        md="6"
                      >
                        <v-text-field
                          v-model="LineDIEdit.libelle_ligne_parametre_domaine_interventions"
                          label="Libellé"
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        sm="6"
                        md="6"
                      >
                        <v-select
                          v-model="LineDIEdit.choise_indicateur"
                          :items="choix"
                          item-text="text"
                          item-value="value"
                          label="Choix de l'indicateur"
                          single-line
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        sm="6"
                        md="6"
                      >
                        <v-text-field
                          v-model="LineDIEdit.mesures"
                          label="Mésure"
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        sm="6"
                        md="6"
                      >
                        <v-select
                          v-model="LineDIEdit.type_valeur"
                          :items="type_valeurs"
                          item-text="text"
                          item-value="value"
                          label="Type de valeur"
                          single-line
                        />
                      </v-col>

                      <v-col
                        cols="12"
                        sm="6"
                        md="6"
                      >
                        <v-select
                          v-model="LineDIEdit.id_composants"
                          :items="composantsList"
                          item-text="nom_web"
                          item-value="id"
                          label="Composant"
                          single-line
                        />
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer />
                  <v-btn
                    color="blue darke-1"
                    @click="close"
                  >
                    Fermer
                  </v-btn>
                  <v-btn
                    color="blue darke-1"
                    @click="saveLineDI"
                  >
                    Enregistrer
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog
              v-model="dialogDelete"
              max-width="500px"
            >
              <v-card>
                <v-card-title class="text-h5">
                  Are you sure you want to delete this item?
                </v-card-title>
                <v-card-actions>
                  <v-spacer />
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="closeDelete"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="deleteItemConfirm"
                  >
                    OK
                  </v-btn>
                  <v-spacer />
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>

        <template v-slot:item.actions="{item}">
          <v-icon
            small
            class="mr-2"
            @click="editItem(item)"
          >
            mdi-pencil
          </v-icon>
          <v-icon
            small
            class="mr-2"
            @click="deleteItem(item)"
          >
            mdi-delete
          </v-icon>
        </template>
        <template v-slot:item.logo_organisations="{item}">
          <v-img
            :lazy-src="item.logo_organisations"
            max-height="150"
            max-width="250"
            :src="item.logo_organisations"
          />
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>

<script>
  import paramettres from '@/services/ligne-domaine-intervention.js'
  import composants from '@/services/composants.js'
  export default {
    data () {
      return {
        dialog: false,
        dialogDelete: false,
        headers: [
          { text: 'Actions', value: 'actions', sortable: false },
          {
            text: 'Indicateur',
            value: 'libelle_ligne_parametre_domaine_interventions',
          },
          {
            text: "Libellé domaine d'intervantion",
            value: 'parametre_di.libelle_parametrage_domaine_interventions',
          },
          {
            text: "Choix de l'indicateur",
            value: 'choise_indicateur',
          },
          {
            text: 'Mésures',
            value: 'mesures',
          },
          {
            text: 'Type de valeur',
            value: 'type_valeur',
          },
          {
            text: 'Libellé composant',
            value: 'composant.nom_web',
          },
        ],
        LinesDI: [],
        choix: [
          {
            text: 'Oui',
            value: '1',
          },
          {
            text: 'Non',
            value: '0',
          },
        ],
        type_valeurs: [
          {
            valeur: 'double',
            text: 'double',
          },
          {
            valeur: 'varchar',
            text: 'varchar',
          },
          {
            valeur: 'file',
            text: 'file',
          },
        ],
        composantsList: [],
        editedIndex: -1,
        LineDIEdit: {
          id: '',
          libelle_ligne_parametre_domaine_interventions: '',
          id_parametrage_domaine_interventions: this.$route.params.DI,
          choise_indicateur: '',
          id_composants: '',
          type_valeur: '',
          mesures: '',

        },
        LineDI: {
          libelle_ligne_parametre_domaine_interventions: '',
          id_parametrage_domaine_interventions: this.$route.params.DI,
          choise_indicateur: '',
          id_composants: '',
          type_valeur: '',
          mesures: '',
        },
      }
    },
    computed: {
      formTitle () {
        return this.editedIndex === -1 ? 'Ajouter une variable' : 'Modifier une variable'
      },
    },
    mounted () {
      console.log(this.editedIndex)
      paramettres.getLinesDI(this.$route.params.DI).then(response => {
        this.LinesDI = response.data
      }).catch(err => {
        console.log(err)
      })
      composants.getComposants().then(response => {
        this.composantsList = response.data
        console.log(this.composantsList)
      }).catch(err => {
        console.log(err)
      })
    },
    methods: {
      saveLineDI () {
        if (this.editedIndex > -1) {
          paramettres.updateLinesDI(this.LineDIEdit).then(response => {
            console.log(response.data)
            paramettres.getLinesDI(this.$route.params.DI).then(response => {
              this.LinesDI = response.data
            })
            this.dialog = false
          }).catch(err => {
            console.log(err.response.data)
          })
        } else {
          paramettres.createLinesDI(this.LineDIEdit).then(response => {
            console.log(response.data)
            paramettres.getLinesDI(this.$route.params.DI).then(response => {
              this.LinesDI = response.data
            })
            this.dialog = false
          }).catch(err => {
            console.log(err.response.data)
          })
        }
        this.close()
      },
      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.LineDIEdit = Object.assign({}, this.LineDI)
          this.editedIndex = -1
        })
      },
      editItem (item) {
        this.editedIndex = this.LinesDI.indexOf(item)
        this.LineDIEdit = Object.assign({}, item)
        this.dialog = true
      },
      deleteItem (item) {
        this.editedIndex = this.LinesDI.indexOf(item)
        this.LineDIEdit = Object.assign({}, item)
        this.dialogDelete = true
      },

      deleteItemConfirm () {
        paramettres.deleteLinesDI(this.LineDIEdit).then(response => {
          paramettres.getLinesDI(this.$route.params.DI).then(response => {
            this.LinesDI = response.data
          })
        }).catch(err => {
          console.log(err.response.data)
        })
        this.closeDelete()
      },
      navigateTo (item) {

      },
      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.LineDIEdit = Object.assign({}, this.LineDI)
          this.editedIndex = -1
        })
      },
    },
  }
</script>

<style scoped>

</style>
